import React from "react";

const EndPage = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 mt-10 flex flex-col md:flex-row justify-center items-center border-t border-gray-700 pt-8 my-8 w-full">
      <p className="text-gray-500 mb-0 text-center">
        © 2024 BlendLLM. All rights reserved.
      </p>
      {/* <div className="flex space-x-4 mt-4 md:mt-0">
        <a href="#" className="text-white hover:text-purple-500">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a href="#" className="text-white hover:text-purple-500">
          <i className="fab fa-twitter"></i>
        </a>
        <a href="#" className="text-white hover:text-purple-500">
          <i className="fab fa-linkedin-in"></i>
        </a>
        <a href="#" className="text-white hover:text-purple-500">
          <i className="fab fa-instagram"></i>
        </a>
      </div> */}
    </div>
  );
};

export default EndPage;
