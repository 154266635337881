import React from "react";
import { useEffect } from "react";
import KeyFeatures from "./KeyFeatures";
import Integrations from "./Integrations";
import Pricing from "./Pricing";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { supabase } from "./Supabase.js";
import Header from "./components/UI/Header.jsx";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const redirectOrScroll = async () => {
      const { data: session } = await supabase.auth.getSession();

      if (session) {
        // Parse the query parameter from the URL
        const params = new URLSearchParams(location.search);
        const redirect = params.get("redirect");
        const scrollTo = params.get("scrollTo");

        if (scrollTo) {
          // Scroll to the pricing section
          const section = document.getElementById(scrollTo);
          if (section) {
            section.scrollIntoView({ behavior: "smooth" });
          }

          // Clean up the URL by removing the query parameter
          const baseUrl = window.location.origin + window.location.pathname;
          window.history.replaceState({}, document.title, baseUrl);
        }

        if (redirect) {
          navigate("/" + redirect);
          // Clean up the URL by removing the query parameter
          const baseUrl = window.location.origin + window.location.pathname;
          window.history.replaceState({}, document.title, baseUrl);
        }
      }
    };

    redirectOrScroll();
  }, [location, navigate]);

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-black via-purple-900 to-black text-white">
      <div className="max-w-6xl mx-auto w-full px-4">
        <Header />

        {/* Hero Section */}
        <section className="flex flex-col items-center justify-center text-center py-24 px-4 md:px-0">
          <h1 className="text-4xl md:text-6xl font-extrabold mb-6 leading-tight tracking-tight text-gray-100">
            Your One-Stop Platform
            <br /> for <span className="text-white">LLM Output Comparison</span>
            .
          </h1>
          <p className="mb-10 text-lg md:text-xl max-w-2xl mx-auto text-gray-300">
            Effortlessly compare results from top LLMs side by side with a
            single, cost-effective subscription.
          </p>
          <button
            className="bg-purple-600 hover:bg-purple-700 px-8 py-4 rounded-full font-semibold text-white shadow-lg transition"
            onClick={() =>
              isLoggedIn
                ? navigate("/compare")
                : navigate("/sign-in?redirectTo=compare")
            }
          >
            Get started
          </button>
        </section>
      </div>

      {/* Add the Key Features Section */}
      <section id="keyFeatures">
        <KeyFeatures />
      </section>
      <section id="integrations">
        <Integrations />
      </section>
      <section id="pricing">
        <Pricing />
      </section>
      <Footer />
    </div>
  );
};

export default Home;
