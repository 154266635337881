import { supabase } from "../Supabase.js";
//import { authActions } from "./auth-slice.js";

export function signInGoogle(redirectTo, scrollTo) {
  return async (dispatch) => {
    console.log("redirectTo - " + redirectTo + " scrollTo - " + scrollTo);
    try {
      supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: redirectTo
            ? "https://blendllm.com/?redirect=" + redirectTo
            : //"http://localhost:3000/?redirect=" + redirectTo
            scrollTo
            ? "https://blendllm.com/?scrollTo=" + scrollTo
            : //"http://localhost:3000/?scrollTo=" + scrollTo
              "",
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function signOutGoogle() {
  return async (dispatch) => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.log(error);
      }
      console.log("Logout Successful");
    } catch (error) {
      console.log("signOutGoogle error - " + JSON.stringify(error));
    }
  };
}

// export function signOutGoogle() {
//   return async (dispatch) => {
//     signOut(auth)
//       .then(() => {
//         dispatch(authActions.signOut());

//         async function logout() {
//           try {
//             const res = await fetch("https://zippynotes.co/api/logout", {
//               method: "POST",
//             });
//             return res;
//           } catch (e) {
//             console.log(e);
//           }
//         }

//         logout().then((res) => {
//           console.log(res);
//         });
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };
// }

// export function verifyAuth() {
//     return async (dispatch) => {
//         onAuthStateChanged(auth, (user) => {
//             if(user) {
//                 dispatch(authActions.signIn({
//                     displayName: user.displayName,
//                     email: user.email,
//                     photoURL: user.photoURL,
//                 }));
//             }
//             else{
//                 dispatch(authActions.signOut());
//             }
//         })
//     }
// }
