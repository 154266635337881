import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import gptIcon from "./assets/chat-gpt.png";
import claudeIcon from "./assets/claude.png";
import geminiIcon from "./assets/gemini.png";
import llamaIcon from "./assets/meta.png";
import mistralIcon from "./assets/mistral.png";
import qwenIcon from "./assets/qwen.png";

const Integrations = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <section
      id="integrations"
      className="text-center py-24 px-4 md:px-0 bg-transparent text-white max-w-6xl mx-auto"
    >
      <div className="mb-16">
        <div className="inline-block px-4 py-2 bg-purple-900 text-sm font-semibold rounded-full mb-10">
          Integrations
        </div>
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Seamlessly Integrate with Leading LLMs
        </h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Experience unparalleled access to the best language models, all in one
          platform.
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="text-orange-500 flex items-center space-x-4">
              <img src={gptIcon} alt="GPT Logo" className="w-8 h-8" />
              <h3 className="text-xl font-semibold text-white">GPT-4</h3>
            </div>
            <button
              onClick={() =>
                isLoggedIn
                  ? navigate("/compare?model=gpt-4o")
                  : navigate("/sign-in")
              }
              className="text-purple-500 hover:text-purple-400 font-semibold"
            >
              Try Now <span className="ml-2">↗</span>
            </button>
          </div>
          <p className="text-gray-400">
            High precision and comprehensive responses.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="text-green-500 flex items-center space-x-4">
              <img src={claudeIcon} alt="Claude Logo" className="w-8 h-8" />
              <h3 className="text-xl font-semibold text-white">Claude 3.5</h3>
            </div>
            <button
              onClick={() =>
                isLoggedIn
                  ? navigate("/compare?model=claude-3-5-sonnet-20241022")
                  : navigate("/sign-in")
              }
              className="text-purple-500 hover:text-purple-400 font-semibold"
            >
              Try Now <span className="ml-2">↗</span>
            </button>
          </div>
          <p className="text-gray-400">Creative and deep contextual outputs.</p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="text-yellow-500 flex items-center space-x-4">
              <img src={geminiIcon} alt="Gemini Logo" className="w-8 h-8" />
              <h3 className="text-xl font-semibold text-white">Gemini</h3>
            </div>
            <button
              onClick={() =>
                isLoggedIn
                  ? navigate("/compare?model=gemini-1.5-pro")
                  : navigate("/sign-in")
              }
              className="text-purple-500 hover:text-purple-400 font-semibold"
            >
              Try Now <span className="ml-2">↗</span>
            </button>
          </div>
          <p className="text-gray-400">
            Balanced insights with superior adaptability.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="text-green-500 flex items-center space-x-4">
              <img src={llamaIcon} alt="Llama Logo" className="w-8 h-8" />
              <h3 className="text-xl font-semibold text-white">Llama 3.1</h3>
            </div>
            <button
              onClick={() =>
                isLoggedIn
                  ? navigate(
                      "/compare?model=meta-llama/Llama-3.2-3B-Instruct-Turbo"
                    )
                  : navigate("/sign-in")
              }
              className="text-purple-500 hover:text-purple-400 font-semibold"
            >
              Try Now <span className="ml-2">↗</span>
            </button>
          </div>
          <p className="text-gray-400">
            Customizable and Lightweight yet powerful for everyday use.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="text-purple-500 flex items-center space-x-4">
              <img src={mistralIcon} alt="Mistral Logo" className="w-8 h-8" />
              <h3 className="text-xl font-semibold text-white">Mistral</h3>
            </div>
            <button
              onClick={() =>
                isLoggedIn
                  ? navigate("/compare?model=mistralai/Mistral-7B-v0.1")
                  : navigate("/sign-in")
              }
              className="text-purple-500 hover:text-purple-400 font-semibold"
            >
              Try Now <span className="ml-2">↗</span>
            </button>
          </div>
          <p className="text-gray-400">
            Streamlined for quick, clear responses.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="flex items-center justify-between mb-4">
            <div className="text-purple-500 flex items-center space-x-4">
              <img src={qwenIcon} alt="Falcon Logo" className="w-8 h-8" />
              <h3 className="text-xl font-semibold text-white">Qwen</h3>
            </div>
            <button
              onClick={() =>
                isLoggedIn
                  ? navigate("/compare?model=Qwen/Qwen1.5-7B")
                  : navigate("/sign-in")
              }
              className="text-purple-500 hover:text-purple-400 font-semibold"
            >
              Try Now <span className="ml-2">↗</span>
            </button>
          </div>
          <p className="text-gray-400">
            Advanced conversational AI with precise responses
          </p>
        </div>
      </div>

      <div className="mt-10">
        <p className="text-gray-400 max-w-2xl mx-auto">
          ...and more leading LLMs at your fingertips.
        </p>
      </div>
    </section>
  );
};

export default Integrations;
