import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EndPage from "./components/UI/EndPage.jsx";

const Footer = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  return (
    <footer className="bg-gradient-to-b from-black/30 to-black/50 text-white pt-16">
      <div className="max-w-6xl mx-auto px-4 flex flex-col md:flex-row justify-center md:justify-between items-center">
        {/* Left Section */}
        <div className="mb-10 md:mb-0 md:w-1/3 text-center md:text-left">
          <h2 className="text-2xl font-bold mb-4">BlendLLM</h2>
          {/* <p className="text-gray-400 mb-6">Become Our Next Proud Investor!</p> */}
          <p className="text-gray-400 mb-6">
            Jump into the future of AI with instant access to top language
            models—all in one place.
          </p>
          <button
            onClick={() =>
              isLoggedIn
                ? navigate("/compare")
                : navigate("/sign-in?redirectTo=compare")
            }
            className="bg-purple-600 hover:bg-purple-700 px-8 py-3 rounded-full font-semibold text-white shadow-lg transition"
          >
            Start Free
          </button>
        </div>

        {/* Right Section - Links */}
        {/* <div className="flex flex-col md:flex-row justify-center md:space-x-16 md:w-2/3 text-center md:text-left">
          <div className="mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Company</h4>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <a href="#">About</a>
              </li>
              <li>
                <a href="#">Blog</a>
              </li>
              <li>
                <a
                  href="https://forms.gle/tXaWZMraBi47RZq18"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:text-purple-400 transition"
                >
                  Contact
                </a>
              </li>
              <li>
                <a href="#">Price</a>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Resources</h4>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a href="#">Feature</a>
              </li>
              <li>
                <a href="#">FAQ</a>
              </li>
              <li>
                <a href="#">Success Story</a>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Authentication</h4>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a href="#">Login</a>
              </li>
              <li>
                <a href="#">Sign up</a>
              </li>
              <li>
                <a href="#">Forgot password</a>
              </li>
              <li>
                <a href="#">Confirm email</a>
              </li>
            </ul>
          </div>

          <div className="mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Utility pages</h4>
            <ul className="space-y-2 text-gray-400">
              <li>
                <a href="#">Style Guide</a>
              </li>
              <li>
                <a href="#">Change log</a>
              </li>
              <li>
                <a href="#">Licenses</a>
              </li>
              <li>
                <a href="#">404 page</a>
              </li>
              <li>
                <a href="#">Protected</a>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
      <EndPage />
    </footer>
  );
};

export default Footer;
