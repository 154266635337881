import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import OutputBox from "./components/ComparisonPage/OutputBox";
import { supabase } from "./Supabase.js";
import { useSearchParams } from "react-router-dom";
import { signOutGoogle } from "./store/auth-actions.js";

const ComparisonPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const model = searchParams.get("model");
  console.log(model);
  const [numOutputs, setNumOutputs] = useState(model ? 1 : 2);
  const [prompt, setPrompt] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initially collapsed
  const [selectedModels, setSelectedModels] = useState(
    model ? [model] : Array(numOutputs).fill("Select LLM")
  );
  const [availableModels, setAvailableModels] = useState([]);
  const [outputs, setOutputs] = useState(
    Array(numOutputs).fill("Model output will be shown here...")
  );
  const [savedSessions, setSavedSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.userData);

  const textareaRef = useRef(); // Ref for textarea
  const [currentSessionId, setCurrentSessionId] = useState(null);


  // Check screen size on initial load to set sidebar state
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        // Expand sidebar for larger screens (tablet and up)
        setSidebarOpen(true);
      } else {
        // Collapse sidebar for smaller screens
        setSidebarOpen(false);
      }
    };

    handleResize(); // Set initial state based on screen size
    window.addEventListener("resize", handleResize); // Update on window resize

    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup on component unmount
    };
  }, []);

  // Fetch available models for the dropdown
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const { data: session } = await supabase.auth.getSession();
        const jwtToken = session?.session.access_token;
  
        const { data, error } = await supabase.functions.invoke("getModels", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        });
  
        if (error || !data?.data) throw new Error("Error fetching models.");
  
        // Filter models with type "chat-completions"
        const chatCompletionModels = data.data
          .filter((model) => model.type === "chat-completion")
          .map((model) => model.id);
  
        setAvailableModels(chatCompletionModels);
      } catch (err) {
        console.error("Error fetching models:", err);
        //alert("Error fetching models. Please try again later.");
      }
    };
  
    fetchModels();
  }, []);
  

  useEffect(() => {
    const fetchSavedSessions = async () => {
      if (!isLoggedIn || !userData) return;

      try {
        //const { data: session } = await supabase.auth.getSession();
        //const jwtToken = session?.session.access_token;

        const { data: sessionsData, error: sessionsError } = await supabase
        .from("chat_sessions")
        .select("id, created_at")
        .eq("user_id", userData.uid)
        .order("created_at", { ascending: false }); 

        if (sessionsError) throw sessionsError;

        const sessionIds = sessionsData.map((session) => session.id);
        const { data: messagesData, error: messagesError } = await supabase
          .from("chat_messages")
          .select("session_id, message")
          .in("session_id", sessionIds)
          .eq("role", "user");

        if (messagesError) throw messagesError;

        const sessions = sessionsData
        .map((session) => {
          const message = messagesData.find(
            (msg) => msg.session_id === session.id
          );
          return { session_id: session.id, prompt: message?.message || null };
        })
        .filter((session) => session.prompt);

        setSavedSessions(sessions);
      } catch (err) {
        console.error("Error fetching saved sessions:", err);
        //alert("Error fetching saved sessions. Please try again later.");
      }
    };
    fetchSavedSessions();
  }, [isLoggedIn, userData]);

  const handleSessionClick = async (sessionId) => {
    try {
      setCurrentSessionId(sessionId);
      const { data: messagesData, error: messagesError } = await supabase
        .from("chat_messages")
        .select("role, message, model")
        .eq("session_id", sessionId)
        .order("id", { ascending: true });

      if (messagesError) throw messagesError;

      const userMessage = messagesData.find(
        (message) => message.role === "user"
      );
      const assistantMessages = messagesData.filter(
        (message) => message.role === "assistant"
      );

      if (userMessage) setPrompt(userMessage.message);

      setNumOutputs(assistantMessages.length);
      setSelectedModels(assistantMessages.map((msg) => msg.model));
      setOutputs(assistantMessages.map((msg) => msg.message));
    } catch (err) {
      console.error("Error fetching session messages:", err);
      //alert("Error fetching session messages. Please try again later.");
    }
  };

  const handleDeleteSession = async (sessionId) => {
    try {
      // Delete messages associated with the session
      const { error: messagesError } = await supabase
        .from("chat_messages")
        .delete()
        .eq("session_id", sessionId);
  
      if (messagesError) {
        console.error("Error deleting messages:", messagesError);
        alert("Failed to delete messages. Please try again.");
        return;
      }
  
      // Delete the session itself
      const { error: sessionError } = await supabase
        .from("chat_sessions")
        .delete()
        .eq("id", sessionId);
  
      if (sessionError) {
        console.error("Error deleting session:", sessionError);
        alert("Failed to delete the session. Please try again.");
        return;
      }
  
      setSavedSessions((prevSessions) => {
        const updatedSessions = prevSessions.filter(
          (session) => session.session_id !== sessionId
        );
  
        if (sessionId === currentSessionId && updatedSessions.length > 0) {
          const mostRecentSession = updatedSessions[0];
          handleSessionClick(mostRecentSession.session_id);
        }
  
        return updatedSessions;
      });
  
      console.log("Session and associated messages deleted successfully!");
    } catch (err) {
      console.error("Unexpected error deleting session and messages:", err);
      alert("An unexpected error occurred. Please try again.");
    }
  };
  
  
  const handleInputChange = (e) => {
    setPrompt(e.target.value);

    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(textarea.scrollHeight, 150)}px`;
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handlePromptSubmit();
    }
  };

  const handlePromptSubmit = async () => {
    if (!userData) {
      alert("User data is not available. Please log in.");
      return;
    }
  
    const { data: session } = await supabase.auth.getSession();
    if (!session) {
      console.error("User is not logged in or session is expired.");
      alert("Your session has expired. Please log in again.");
      return;
    }
  
    const jwtToken = session.session.access_token;

    if (!prompt.trim()) {
      console.log("Prompt is empty. No API calls will be made.");
      return;
    }
  
    // Ensure at least one model is selected
    if (selectedModels.every((model) => model === "Select LLM")) {
      // Clear the outputs if no model is selected
      setOutputs([]);
      return;
    }
  
    setIsLoading(true);
  
    try {
      const sessionRes = await supabase.functions.invoke("createSession", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: JSON.stringify({ userId: userData.uid }),
      });
  
      if (sessionRes.error) {
        throw new Error(sessionRes.error.message);
      }
  
      const sessionId = sessionRes.data[0].id;
  
      // Process each model independently
      const outputsWithErrors = await Promise.all(
        selectedModels.map(async (model) => {
          if (model === "Select LLM") {
            // Skip API call and set blank output for unselected models
            return {
              model,
              output: "", // Blank for unselected models
            };
          }
  
          try {
            const compareRes = await supabase.functions.invoke("compareLLMs", {
              method: "POST",
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
              body: JSON.stringify({
                prompt: prompt,
                sessionId: sessionId,
                selectedModels: [model],
                uid: userData.uid,
              }),
            });
  
            console.log(`Response for model ${model}`, compareRes);
  
            const statusCode = compareRes.error?.context?.status;
  
            if (statusCode === 509) {
              return {
                model,
                output:
                  "You've exhausted the number of tokens. Redirecting to the pricing section...",
                redirect: true,
              };
            }
  
            if (!compareRes || !compareRes.data || !compareRes.data.data[model]) {
              return {
                model,
                output:
                  "The selected Model is down now. Please try later or contact us if the issue persists.",
              };
            }
  
            // Successful response
            return { model, output: compareRes.data.data[model] };
          } catch (err) {
            console.error(`Error for model ${model}`, err);
            return {
              model,
              output:
                "An unexpected error occurred. Please try again later or contact support.",
            };
          }
        })
      );
  
      const newOutputs = selectedModels.map((model) => {
        const result = outputsWithErrors.find((res) => res.model === model);
        return result ? result.output : ""; // Default to blank if no result
      });
  
      setOutputs(newOutputs);
  
      // Handle redirection if any model exhausts tokens
      const tokenExhausted = outputsWithErrors.some((res) => res.redirect);
      if (tokenExhausted) {
        setTimeout(() => {
          navigate("/?scrollTo=pricing");
        }, 3000);
      }
  
      // Save the current session
      setSavedSessions((prevSessions) => [
        
        { session_id: sessionId, prompt },
        ...prevSessions,
      ]);
    } catch (err) {
      console.error("Error comparing LLMs:", err);
  
      // Fallback for unexpected errors
      const errorMessage =
        "An unexpected error occurred. Please try again later or contact support.";
      setOutputs(selectedModels.map(() => errorMessage));
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleLayoutChange = (e) => {
    const newNumOutputs = parseInt(e.target.value);
    setNumOutputs(newNumOutputs);
    setSelectedModels(Array(newNumOutputs).fill("Select LLM"));
    setOutputs(Array(newNumOutputs).fill("Model output will be shown here..."));
  };

  const handleModelChange = (index, event) => {
    const newModels = [...selectedModels];
    newModels[index] = event.target.value;
    setSelectedModels(newModels);
  };

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-black via-purple-900 to-black text-white overflow-hidden">
      {/* Sidebar */}
      <div
        className={`${
          sidebarOpen ? "w-72 sm:w-80 lg:w-64" : "w-10 sm:w-12 lg:w-16"
        } transition-all duration-300 bg-gradient-to-b from-gray-900 to-purple-900 fixed top-0 bottom-0 left-0 z-50 flex flex-col`}
      >
        {/* Sidebar Header */}
        <div className={`p-4 ${!sidebarOpen ? "hidden" : "block"}`}>
          <div className="flex items-center justify-between mb-8">
            <a
              href="/"
              className="text-2xl font-bold text-white hover:text-gray-300 transition duration-200"
            >
              BlendLLM
            </a>
            <button
              onClick={() => setSidebarOpen(false)}
              className="text-white hover:text-gray-400 focus:outline-none"
            >
              <XIcon className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Scrollable Saved Sessions List */}
        {sidebarOpen && (
          <>
            <div className="flex items-center justify-between px-4 mb-4">
              <h2 className="text-lg font-semibold text-gray-300">History</h2>
              <button
                className="text-gray-400 hover:text-white transition text-2xl font-bold"
                onClick={() => window.location.reload()}
                title="Add New Comparison"
              >
                +
              </button>
            </div>
            
            <div className="flex-grow overflow-y-auto px-4 pb-4">
              {savedSessions.length > 0 ? (
                savedSessions.map((session) => (
                  <div
                    key={session.session_id}
                    className="relative cursor-pointer p-2 bg-gray-700 rounded-lg hover:bg-purple-700 transition overflow-hidden whitespace-nowrap truncate mb-2 flex items-center"
                    onClick={() => handleSessionClick(session.session_id)}
                  >
                    <span className="truncate pr-8">{session.prompt}</span>

                    {/* Delete Button */}
                    <button
                      className="absolute right-2 text-gray-400 hover:text-red-500 transition"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteSession(session.session_id);
                      }}
                    >
                      ✕
                    </button>
                  </div>
                ))
              ) : (
                <p className="text-gray-400">No sessions available</p>
              )}
            </div>


            <div className="mt-4 p-4 bg-gray-800 border-t border-gray-700">
              <button
                onClick={() => {
                  if (window.confirm("Are you sure you want to sign out?")) {
                    dispatch(signOutGoogle());
                    navigate("/");
                  }
                }}
                className="w-full px-4 py-2 text-sm font-semibold text-white bg-purple-600 hover:bg-purple-700 rounded-lg transition"
              >
                Sign Out
              </button>
            </div>
          </>
        )}

        {/* Sidebar Toggle Button */}
        {!sidebarOpen && (
          <div className="flex flex-col items-center pt-4">
            <button
              onClick={() => setSidebarOpen(true)}
              className="text-white hover:text-gray-400 focus:outline-none"
            >
              <MenuIcon className="h-8 w-8" />
            </button>
          </div>
        )}
      </div>

      {/* Content Area */}
      <div
        className={`flex-grow flex flex-col p-4 sm:p-6 ${
          sidebarOpen ? "ml-72 sm:ml-80 lg:ml-64" : "ml-10 sm:ml-12 lg:ml-16"
        } transition-all duration-300 overflow-y-auto`}
      >
        <div className="bg-gray-800 rounded-lg p-3 mb-4 shadow-lg flex space-x-4 items-center">
          <textarea
            ref={textareaRef}
            value={prompt}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            className="flex-grow p-2 bg-gray-700 text-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 resize-none overflow-y-auto"
            placeholder="Type your prompt here..."
            rows={1}
            style={{ maxHeight: "150px" }}
          />
          <button
            onClick={handlePromptSubmit}
            disabled={isLoading}
            className={`ml-3 px-4 py-2 rounded-lg font-semibold transition text-white shadow-lg ${
              isLoading
                ? "bg-gray-600 cursor-not-allowed"
                : "bg-purple-600 hover:bg-purple-700"
            }`}
          >
            Submit
          </button>
        </div>

        <div className="mb-4">
          <h2 className="text-lg font-semibold mb-2">
            Select Number of Outputs
          </h2>
          <select
            value={numOutputs}
            onChange={handleLayoutChange}
            className="bg-gray-700 text-gray-300 p-2 rounded-lg shadow-lg pr-8"
          >
            <option value="1">1 Output</option>
            <option value="2">2 Outputs</option>
            <option value="3">3 Outputs</option>
            <option value="4">4 Outputs</option>
            <option value="5">5 Outputs</option>
            <option value="6">6 Outputs</option>
          </select>
        </div>

        <div
          className={`grid gap-4 ${
            numOutputs === 1 ? "grid-cols-1" : "grid-cols-1 md:grid-cols-2"
          } auto-rows-min`}
        >
          {Array.from({ length: numOutputs }).map((_, index) => (
            <div
              key={index}
              className="flex flex-col p-4 bg-gray-800 rounded-lg shadow-lg w-full"
            >
              <OutputBox
                modelName={selectedModels[index]}
                output={isLoading ? "Loading..." : outputs[index]}
                models={availableModels}
                onModelChange={(event) => handleModelChange(index, event)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ComparisonPage;
