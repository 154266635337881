import React from "react";
import splitScreenIcon from "./assets/split-screen.png";
import walletIcon from "./assets/wallet.png";
import researchIcon from "./assets/research.png";

const KeyFeatures = () => {
  return (
    <section
      id="keyFeatures"
      className="text-center py-24 px-4 md:px-0 bg-transparent text-white max-w-6xl mx-auto"
    >
      <div className="mb-16">
        <div className="inline-block px-4 py-2 bg-purple-900 text-sm font-semibold rounded-full mb-10">
          Key Feature
        </div>
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Explore BlendLLM Key Features
        </h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Explore the Power of Comprehensive LLM Comparison
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="text-purple-400 mb-4">
            <img
              src={splitScreenIcon}
              alt="Unified Interface Icon"
              className="w-10 h-10 mx-auto"
            />
          </div>
          <h3 className="text-xl font-semibold mb-2">Unified Interface</h3>
          <p className="text-gray-400">
            View and contrast outputs from GPT, Claude, Gemini, and more in a
            seamless layout.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="text-purple-400 mb-4">
            <img
              src={walletIcon}
              alt="Cost-Effective Subscription Icon"
              className="w-10 h-10 mx-auto"
            />
          </div>
          <h3 className="text-xl font-semibold mb-2">
            Cost-Effective Subscription
          </h3>
          <p className="text-gray-400">
            One subscription grants access to multiple LLMs, saving you money.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="text-purple-400 mb-4">
            <img
              src={researchIcon}
              alt="Research-Friendly Tools Icon"
              className="w-10 h-10 mx-auto"
            />
          </div>
          <h3 className="text-xl font-semibold mb-2">
            Research-Friendly Tools
          </h3>
          <p className="text-gray-400">
            Optimized for researchers and teams looking to evaluate model
            performances.
          </p>
        </div>

        {/* <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="text-purple-400 mb-4">
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6v14h16V6M4 10h16M12 10v10"
              ></path>
            </svg>
          </div>
          <h3 className="text-xl font-semibold mb-2">Simple Data Syncing</h3>
          <p className="text-gray-400">
            Sync data effortlessly across platforms.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="text-purple-400 mb-4">
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M7 8v8m5-8v8m5-8v8M7 10h10"
              ></path>
            </svg>
          </div>
          <h3 className="text-xl font-semibold mb-2">Member Management</h3>
          <p className="text-gray-400">
            Effortlessly manage team members' roles.
          </p>
        </div>

        <div className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg">
          <div className="text-purple-400 mb-4">
            <svg
              className="w-8 h-8 mx-auto"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M3 6h18M3 10h18M3 14h18M3 18h18"
              ></path>
            </svg>
          </div>
          <h3 className="text-xl font-semibold mb-2">Deadline Tracking</h3>
          <p className="text-gray-400">
            Stay on the schedule with clear deadlines.
          </p>
        </div>*/}
      </div>
    </section>
  );
};

export default KeyFeatures;
