import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { supabase } from "./Supabase.js";

const Pricing = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.userData);
  const [loading, setLoading] = useState(false); // State for loading

  const [currentPlan, setCurrentPlan] = useState();

  useEffect(() => {
    if (isLoggedIn && userData?.uid) {
      const fetchSubscription = async () => {
        const { data, error } = await supabase
          .from("user_details")
          .select("current_plan")
          .eq("user_id", userData.uid)
          .single();

        if (data && data.current_plan) {
          setCurrentPlan(data.current_plan.split(" ")[0]);
        }

        if (error) {
          console.log("Error occured while fetching user - " + error.message);
        }
      };

      fetchSubscription();
    }
  }, [isLoggedIn, userData]);

  async function createStripeCheckoutSession(priceId) {
    if (!isLoggedIn || !userData.uid) {
      navigate("/sign-in?scrollTo=pricing");
      return;
    }

    const { data: session, error } = await supabase.auth.getSession();

    if (error || !session) {
      console.error("Error fetching session:", error?.message);
      alert("Failed to authenticate. Please log in again.");
      return;
    }

    const jwtToken = session.session.access_token;

    setLoading(true); // Start loading

    const res = await fetch(
      "https://afrxnwvpgsajpjckxbpf.supabase.co/functions/v1/createCheckoutSession",
      {
        method: "POST",
        body: JSON.stringify({
          uid: userData.uid,
          priceId: priceId,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );

    const json = await res.json();

    setLoading(false); // End loading

    if (res.ok) {
      window.open(json.url, "_blank");
      return;
    } else {
      console.error("Error:", json.error);
      alert("Something went wrong. Please try again.");
      return;
    }
  }

  const tiers = [
    {
      name: "Starter",
      price_tier: "free",
      description: "Explore the basics for free",
      monthlyPrice: 0,
      yearlyPrice: 0,
      features: ["✔ Free Platform Access", "✔ Limited Tokens"],
      stripePriceId: null, // No Stripe ID for the free plan
    },
    {
      name: "Pro",
      price_tier: "pro",
      description: "For professionals and power users",
      monthlyPrice: 29,
      yearlyPrice: 299, // Assuming a 10-month discount for yearly
      features: [
        "✔ 10 Million Tokens per month",
        "✔ Access 100+ Models",
        "✔ Email Support",
        "✔ Free Platform Access",
      ],
      stripePriceId: {
        //test mode IDs
        // monthly: "price_1QLmVKL3Jto9zCvRd6ShDOwx",
        // yearly: "price_1QLmVzL3Jto9zCvRSsY20J68",

        //live IDs
        monthly: "price_1QQ6hhL3Jto9zCvRsvhFn6GO",
        yearly: "price_1QQ6hhL3Jto9zCvRkrPISlOM",
      },
    },
    {
      name: "Enterprise",
      price_tier: "enterprise",
      description: "Built for teams and businesses",
      monthlyPrice: 99,
      yearlyPrice: 999, // Assuming a 10-month discount for yearly
      features: [
        "✔ 50 Million Tokens per month",
        "✔ Access 100+ Models",
        "✔ Priority Support",
        "✔ Free Platform Access",
        "✔ Custom Billing Options",
      ],
      stripePriceId: {
        //test mode IDs
        // monthly: "price_1QLmWLL3Jto9zCvRym02LbVS",
        // yearly: "price_1QLmWeL3Jto9zCvRoceQcVFr",

        //live IDs
        monthly: "price_1QQ6hdL3Jto9zCvRkZtXgWgg",
        yearly: "price_1QQ6hdL3Jto9zCvRamoojDTj",
      },
    },
  ];

  const [billingCycle, setBillingCycle] = useState("Yearly");

  return (
    <section
      id="pricing"
      className="text-center py-24 px-4 md:px-0 bg-transparent text-white max-w-6xl mx-auto"
    >
      <div className="mb-16">
        <div className="inline-block px-4 py-2 bg-purple-900 text-sm font-semibold rounded-full mb-10">
          Best Price
        </div>
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Flexible Pricing to Suit Your Needs
        </h2>
        <p className="text-gray-400 max-w-2xl mx-auto">
          Choose the perfect plan that fits your research or organizational
          requirements.
        </p>
      </div>

      <div className="flex justify-center mb-12">
        <div className="bg-black/50 rounded-full p-1 flex items-center space-x-2">
          <button
            className={`px-6 py-2 rounded-full ${
              billingCycle === "Monthly"
                ? "bg-purple-900 text-white font-semibold"
                : "text-white hover:bg-purple-900 transition"
            }`}
            onClick={() => setBillingCycle("Monthly")}
          >
            Monthly
          </button>
          <button
            className={`px-6 py-2 rounded-full ${
              billingCycle === "Yearly"
                ? "bg-purple-900 text-white font-semibold"
                : "text-white hover:bg-purple-900 transition"
            }`}
            onClick={() => setBillingCycle("Yearly")}
          >
            Yearly
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {tiers.map((tier, index) => (
          <div
            key={index}
            className="bg-gradient-to-b from-black/30 to-black/50 rounded-xl p-6 shadow-lg relative"
          >
            {tier.name === "Pro" && (
              <div className="absolute top-4 right-4 bg-yellow-500 text-black px-3 py-1 rounded-full text-xs font-semibold">
                Popular
              </div>
            )}

            <h3 className="text-2xl font-semibold mb-2">{tier.name}</h3>
            <p className="text-gray-400 mb-4">{tier.description}</p>

            <div className="text-4xl font-bold mb-4">
              $
              {billingCycle === "Monthly"
                ? tier.monthlyPrice
                : Math.round(tier.yearlyPrice / 12)}{" "}
              <span className="text-lg font-normal">/ Month</span>
            </div>

            {
              <button
                className={`${
                  loading
                    ? "cursor-not-allowed bg-purple-800"
                    : "bg-purple-600 hover:bg-purple-700"
                } px-8 py-3 rounded-full font-semibold text-white shadow-lg transition mb-6`}
                onClick={() => {
                  if (
                    currentPlan &&
                    (currentPlan === "pro" || currentPlan === "enterprise")
                  ) {
                    navigate("/compare");
                  } else if (!loading && tier.stripePriceId) {
                    const selectedPriceId =
                      billingCycle === "Monthly"
                        ? tier.stripePriceId.monthly
                        : tier.stripePriceId.yearly;
                    createStripeCheckoutSession(selectedPriceId);
                  } else if (!loading) {
                    if (isLoggedIn) navigate("/compare");
                    else navigate("/sign-in?scrollTo=pricing");
                  }
                }}
                disabled={loading} // Disable button during loading
              >
                {currentPlan === "pro" || currentPlan === "enterprise"
                  ? "Get started"
                  : loading && tier.stripePriceId
                  ? "Loading..."
                  : !tier.stripePriceId || !isLoggedIn
                  ? "Get Started"
                  : "Subscribe"}
              </button>
            }

            <div className="text-left">
              <ul className="text-gray-300 space-y-2">
                {tier.features.map((feature, i) => (
                  <li key={i}>{feature}</li>
                ))}
              </ul>
            </div>

            {currentPlan &&
              ((currentPlan === "pro" && tier.price_tier === "pro") ||
                (currentPlan === "enterprise" &&
                  tier.price_tier === "enterprise")) && (
                <div className="text-gray-400 mx-auto mt-6 text-center">
                  You're on the {tier.name} plan!
                </div>
              )}
          </div>
        ))}
      </div>

      <div className="mt-10">
        <p className="text-gray-400 max-w-2xl mx-auto">
          Looking for something else?{" "}
          <a
            href="/contact"
            target="_blank"
            className="text-purple-400 hover:underline"
          >
            Contact us
          </a>{" "}
          for a customized plan tailored to your needs.
        </p>
      </div>
    </section>
  );
};

export default Pricing;
