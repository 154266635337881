import { useEffect } from "react";
import { createClient } from "@supabase/supabase-js";
//import { Auth } from "@supabase/auth-ui-react";
//import { ThemeSupa } from "@supabase/auth-ui-shared";

import React from "react";
import Home from "./Home";
import ComparisonPage from "./ComparisonPage";
import SignInPage from "./SignInPage";

import {
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

import { useDispatch } from "react-redux";
import { authActions } from "./store/auth-slice.js";
import RootLayout from "./components/Root.jsx";
import ContactUs from "./ContactUs.jsx";
import Announcements from "./Announcements.jsx";

const supabase = createClient(
  "https://afrxnwvpgsajpjckxbpf.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFmcnhud3ZwZ3NhanBqY2t4YnBmIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ1ODU0NzQsImV4cCI6MjA0MDE2MTQ3NH0.BEuklgos1pKuGGla0b3IBmcfak0zNwGjhhTMO1buFQQ"
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/compare",
        element: <ComparisonPage />,
        // loader: () => {
        //   if (!auth.currentUser) {
        //     return redirect("/");
        //   } else {
        //     return null;
        //   }
        // },
      },
      {
        path: "/sign-in",
        element: <SignInPage />,
      },
      {
        path: "contact",
        element: <ContactUs />,
      },
      {
        path: "Announcements",
        element: <Announcements />,
      },
      // Uncomment and add other pages as needed
      // {
      //   path: "/pricing",
      //   element: <PricingPage />,
      // },
      // {
      //   path: "/contact-us",
      //   element: <ContactUsPage />,
      // },
    ],
  },
]);

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      //setSession(session);
      //dispatch(authActions.)
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      console.log("session --- " + JSON.stringify(session));
      //setSession(session);
      if (session) {
        const userData = session.user;
        console.log("userData --- " + JSON.stringify(userData));

        const user_data = {
            uid: userData.id,
            displayName: userData.user_metadata.full_name,
            email: userData.email,
            avatarURL: userData.user_metadata.avatar_url,
        }

        dispatch(
          authActions.signIn(user_data)
        );

        handleUserDetails(user_data);
      } else {
        dispatch(authActions.signOut());
      }
    });

    return () => subscription.unsubscribe();
  }, [dispatch]);

  async function handleUserDetails(user) {
    try {
      const { data: userDetails, error: userDetailsError } = await supabase
        .from("user_details")
        .select("id")
        .eq("user_id", user.uid)
        .limit(1);
      if (userDetailsError) {
        console.error("Error checking user_details:", userDetailsError.message);
        return;
      }
      if (userDetails.length === 0) {
        const { error: insertError } = await supabase.from("user_details").insert({
          stripe_id: "",
          user_id: user.uid,
          display_name: user.displayName,
          email: user.email,
          number_tokens: 20000,
          current_plan: "free",
        });
        if (insertError) {
          console.error("Error inserting into user_details:", insertError.message);
        } else {
          console.log("New user added to user_details.");
        }
      } else {
        console.log("User already exists in user_details.");
      }
    } catch (error) {
      console.error("Error in handleUserDetails:", error.message);
    }
  }

  // if (!session) {
  //   return <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} />;
  // } else {
  //   return (
  //     <div className="App">
  //       <ComparisonPage />
  //     </div>
  //   );
  // }

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
