import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const OutputBox = ({
  modelName,
  output,
  models,
  onModelChange,
  isFullHeight,
}) => {
  return (
    <div
      className={`bg-gray-800 p-0 rounded-lg shadow-lg flex flex-col ${
        isFullHeight
          ? "h-full flex-grow"
          : "min-h-[14rem] md:min-h-[18rem] lg:min-h-[22rem]"
      }`}
    >
      {/* Dropdown for Selecting LLM */}
      <div className="mb-4">
        <select
          value={modelName}
          onChange={onModelChange}
          className="w-full bg-gray-700 text-gray-200 p-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
        >
          <option value="">Select LLM</option>
          {models.map((model, index) => (
            <option key={index} value={model}>
              {model}
            </option>
          ))}
        </select>
      </div>

      {/* Display the Output */}
      <div className="flex-grow overflow-auto bg-gray-900 p-4 rounded-lg min-h-[14rem] max-h-[24rem] md:min-h-[18rem] md:max-h-[28rem] lg:min-h-[22rem] lg:max-h-[32rem]">
        <ReactMarkdown
          children={output || "Output will be displayed here..."}
          remarkPlugins={[remarkGfm]} // Support for GitHub Flavored Markdown (e.g., bold, tables)
          className="text-gray-300 whitespace-pre-wrap break-words"
        />
      </div>
    </div>
  );
};

export default OutputBox;
