import React from "react";
import EndPage from "./components/UI/EndPage.jsx";
import Header from "./components/UI/Header.jsx";

const Announcements = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-black via-purple-900 to-black text-white">
      <div className="max-w-6xl mx-auto w-full px-4">
        <Header />
      </div>

      {/* Announcements Section */}
      <div className="flex flex-col justify-center items-center flex-grow px-4 text-center">
        {/* Page Header */}
        <h1 className="text-4xl font-extrabold mb-6">Announcements</h1>
        <p className="text-lg text-gray-300 mb-10">
          Stay up to date with the latest news and updates from BlendLLM.
        </p>

        {/* Announcements Content */}
        <div className="space-y-8 text-left max-w-4xl">
          {/* Announcement 1 */}
          <div className="p-6 bg-purple-800/50 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-purple-300 mb-2">
              Upcoming Feature: Context-Aware Chat
            </h2>
            <p className="text-gray-300">
              We're thrilled to announce that soon, users will be able to carry
              forward the context of their previous chat history into new
              prompts. This will enable more dynamic and meaningful interactions
              when comparing LLM outputs.
            </p>
            <p className="text-sm text-gray-400 mt-2">Posted: Nov 27, 2024</p>
          </div>

          {/* Announcement 2 */}
          <div className="p-6 bg-purple-800/50 rounded-lg shadow-lg">
            <h2 className="text-2xl font-bold text-purple-300 mb-2">
              Upcoming Feature: Improved UI for Code Prompts
            </h2>
            <p className="text-gray-300">
              We are excited to unveil a sleek, user-friendly interface designed
              specifically for code-related prompts. This improvement will
              enhance the experience for developers comparing outputs of LLMs
              for coding tasks.
            </p>
            <p className="text-sm text-gray-400 mt-2">Posted: Nov 24, 2024</p>
          </div>
        </div>
      </div>
      <EndPage />
    </div>
  );
};

export default Announcements;
