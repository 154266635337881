import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInGoogle } from "./store/auth-actions.js";
import { ReactComponent as GoogleLogo } from "./assets/google-logo.svg";

const SignInPage = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [redirectTo, setRedirectTo] = useState("");
  const [scrollTo, setScrollTo] = useState("");

  // Check for query parameter on component mount
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const redirectParam = params.get("redirectTo");
    const scrollParam = params.get("scrollTo");
    setRedirectTo(redirectParam);
    setScrollTo(scrollParam);
  }, []);

  function signInHandler() {
    dispatch(signInGoogle(redirectTo, scrollTo));
  }

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-black via-purple-900 to-black text-white text-center px-6">
      <h1 className="text-4xl font-extrabold mb-6">Sign In to BlendLLM</h1>
      <p className="mb-10 text-lg text-gray-300">
        Please sign in to access all features and start comparing LLM outputs.
      </p>
      {!isLoggedIn && (
        <button
          className="flex space-x-3 border px-6 py-3 rounded-full font-semibold transition border-purple-600 hover:bg-purple-500"
          onClick={signInHandler}
        >
          <GoogleLogo className="h-6 w-6 align-middle" />
          <span className="align-middle">Sign in with Google</span>
        </button>
      )}
      {isLoggedIn && (
        <p className="text-lg text-green-400">You are already signed in!</p>
      )}
    </div>
  );
};

export default SignInPage;
