import React, { useState } from "react";
import { ReactComponent as GoogleLogo } from "../../assets/google-logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { signInGoogle, signOutGoogle } from "../../store/auth-actions.js";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userData = useSelector((state) => state.auth.userData);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleSmoothScroll = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigation = (id) => {
    if (location.pathname === "/") {
      handleSmoothScroll(id);
    } else {
      navigate(`/?scrollTo=${id}`);
    }
    closeSidebar(); // Close the sidebar after navigation
  };

  function signInHandler() {
    dispatch(signInGoogle());
    closeSidebar(); // Close the sidebar after Sign In
  }

  function signOutHandler() {
    dispatch(signOutGoogle());
    navigate("/");
    closeSidebar(); // Close the sidebar after Sign Out
  }

  function contactUsNavigationHandler() {
    navigate("/contact");
    closeSidebar(); // Close the sidebar after navigation
  }

  function announcementsNavigationHandler() {
    navigate("/announcements");
    closeSidebar(); // Close the sidebar after navigation
  }

  return (
    <header className="relative">
      {/* Top Bar */}
      <div className="flex justify-between items-center p-6 text-white shadow-lg">
        {/* Logo Section */}
        <div className="text-2xl font-extrabold">
          <a href="/" className="hover:text-purple-400">
            BlendLLM
          </a>
        </div>

        {/* Navigation Tabs for Desktop */}
        <nav className="hidden md:flex space-x-8 text-lg">
          <button
            onClick={() => handleNavigation("keyFeatures")}
            className="hover:text-purple-400 transition"
          >
            Features
          </button>
          <button
            onClick={() => handleNavigation("pricing")}
            className="hover:text-purple-400 transition"
          >
            Pricing
          </button>
          <button
            onClick={announcementsNavigationHandler}
            className="hover:text-purple-400 transition"
          >
            Announcements
          </button>
          <button
            onClick={contactUsNavigationHandler}
            className="hover:text-purple-400 transition"
          >
            Contact
          </button>
        </nav>

        {/* Authentication Button for Desktop */}
        <div className="hidden md:flex space-x-4">
          <button
            className={`flex space-x-3 border px-5 py-2 rounded-full font-semibold transition border-purple-600 hover:bg-purple-500`}
            onClick={isLoggedIn ? signOutHandler : signInHandler}
          >
            {isLoggedIn ? (
              <img
                src={userData?.avatarURL}
                referrerPolicy="no-referrer"
                alt="Google Profile Pic"
                className="h-6 w-6 rounded-full"
              />
            ) : (
              <GoogleLogo className="h-6 w-6 align-middle" />
            )}
            <span className="align-middle">
              {isLoggedIn ? "Sign Out" : "Sign In"}
            </span>
          </button>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden text-white focus:outline-none"
          onClick={toggleSidebar}
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>

      {/* Sidebar for Mobile */}
      {isSidebarOpen && (
        <div className="fixed top-0 left-0 w-64 h-screen bg-black text-white z-50 shadow-lg">
          <div className="p-6 flex justify-between items-center">
            {/* Logo Section */}
            <div className="text-2xl font-extrabold">
              <a
                href="/"
                className="hover:text-purple-400"
                onClick={closeSidebar}
              >
                BlendLLM
              </a>
            </div>
            {/* Close Button */}
            <button
              className="text-white focus:outline-none"
              onClick={closeSidebar}
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <nav className="flex flex-col space-y-4 text-lg p-6">
            <button
              onClick={() => handleNavigation("keyFeatures")}
              className="hover:text-purple-400 transition text-left"
            >
              Features
            </button>
            <button
              onClick={() => handleNavigation("pricing")}
              className="hover:text-purple-400 transition text-left"
            >
              Pricing
            </button>
            <button
              onClick={announcementsNavigationHandler}
              className="hover:text-purple-400 transition text-left"
            >
              Announcements
            </button>
            <button
              onClick={contactUsNavigationHandler}
              className="hover:text-purple-400 transition text-left"
            >
              Contact
            </button>
          </nav>
          {/* Authentication Button for Mobile */}
          <div className="mt-auto p-6">
            <button
              className={`flex space-x-3 border px-5 py-2 rounded-full font-semibold transition border-purple-600 hover:bg-purple-500`}
              onClick={isLoggedIn ? signOutHandler : signInHandler}
            >
              {isLoggedIn ? (
                <img
                  src={userData?.avatarURL}
                  referrerPolicy="no-referrer"
                  alt="Google Profile Pic"
                  className="h-6 w-6 rounded-full"
                />
              ) : (
                <GoogleLogo className="h-6 w-6 align-middle" />
              )}
              <span className="align-middle">
                {isLoggedIn ? "Sign Out" : "Sign In"}
              </span>
            </button>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
